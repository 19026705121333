import axios from '@/axios.js'

const state = () => ({
    menuitems: [],
})

const mutations = {
  SET_MENUITEMS (state, payload) {
    state.menuitems = payload
  },
  ADD_MENUITEM (state, item) {
    var i;
    for (i = 0; i < item.length; i++) { 
      state.menuitems.unshift(item[i])
    }
  },
  UPDATE_MENUITEM (state, menuitem) {
    const menuitemIndex = state.menuitems.findIndex((p) => p.id === menuitem.id)
    Object.assign(state.menuitems[menuitemIndex], menuitem)
  },
  REMOVE_MENUITEM (state, itemId) {
    const ItemIndex = state.menuitems.findIndex((p) => p.id === itemId)
    state.menuitems.splice(ItemIndex, 1)
  },
}

const actions = {
  fetchMenuitem ({ commit }, nav_menu) {
    return new Promise((resolve, reject) => {
      axios.get(`/menuitem-list/${nav_menu}`)
        .then((response) => {
          commit('SET_MENUITEMS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addMenuitem ({ commit }, menuitem ) {
    return new Promise((resolve, reject) => {
      axios.post('/menuitem-add', menuitem)
        .then((response) => {
          commit('ADD_MENUITEM', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateMenuitem ({ commit }, menuitem) {
    return new Promise((resolve, reject) => {
      axios.post(`/menuitem-update/${menuitem.id}`, menuitem)
        .then((response) => {
          commit('UPDATE_MENUITEM', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeMenuitem ({ commit }, menuitem) {
    return new Promise((resolve, reject) => {
      axios.delete(`/menuitem-delete/${menuitem.id}`)
        .then((response) => {
          commit('REMOVE_MENUITEM', menuitem.id)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}